<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
    :persistent="load"
  >
    <v-card>
      <v-card-title>
        Crear usuario
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <p
          v-html="`Crear un usuario para el vendedor <strong>${nombre}</strong>:`"
          class="text-left"
        ></p>
        <v-form ref="form" class="mx-2">
          <v-text-field
            v-model.trim="user"
            label="Usuario"
            tabindex="1"
            :rules="[rules.required]"
            validate-on-blur
            autofocus
          ></v-text-field>
          <v-text-field
            v-model.trim="pass"
            label="Clave"
            hint="Mínimo 6 caracteres"
            tabindex="1"
            :rules="[rules.min]"
            :append-icon="show_pass ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass = !show_pass"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass_repeat"
            label="Repita la clave"
            tabindex="1"
            :rules="[rules.pass_confirm]"
            :append-icon="show_pass_repeat ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_repeat ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass_repeat = !show_pass_repeat"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          color="success"
          :loading="load"
          :texto="`¿Desea crear el usuario <strong style='text-transform: uppercase'>${user}</strong> para el vendedor <strong>${nombre}</strong>?`"
          @action="crear_usuario"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      show_pass: false,
      show_pass_repeat: false,
      user: '',
      pass: '',
      pass_repeat: '',
      rules: {
        required: value => !!value || 'Campo requerido',
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass_confirm: value => value === this.pass || 'Las claves no coinciden',
      },
    }
  },
  components: {
    BtnConfirmar,
  },
  props: {
    value: Boolean,
    vendedor: Number,
    sucursal: Number,
    empresa: Number,
    nombre: String,
    categoria: Number,
    vendedores_list: Array,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    vendedores: {
      get () {
        return this.vendedores_list
      },
      set (value) {
        this.$emit('update:vendedores_list', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async crear_usuario () {
      if (this.$refs.form.validate()) {
        this.load = true
        
        await this.$store.dispatch('vendedores/crear_usuario_vendedor', {
          user: this.user,
          pass: this.pass,
          vendedor: this.vendedor,
          sucursal: this.sucursal,
          empresa: this.empresa,
          tipo: this.categoria
        })
          .then((res) => {
            this.vendedores.find(ve => ve.codigo === this.vendedor).usuario = res.user
            this.dialog = false

            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success',
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        
        this.load = false
      }
    },
    clear () {
      this.$refs.form.resetValidation()
      this.show_pass = false
      this.show_pass_repeat = false
      this.user = ''
      this.pass = ''
      this.pass_repeat = ''
    }
  }
}
</script>